import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';
import { motion } from 'framer-motion';
import ScrollAnimation from '../components/scrollanimation';
import {
    Newspaper,
    PeopleGroup,
    Calendar,
    Planet,
    CheckBoxFill,
} from 'akar-icons';
import { Link } from 'gatsby';

export default function FeaturesPage() {
    const start = {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.45,
            ease: 'easeInOut',
        },
    };
    const end = {
        y: '-1vw',
        opacity: 0,
        transition: {
            duration: 0.15,
            ease: 'easeInOut',
        },
    };

    const divOneAnimation = ScrollAnimation(start, end, 0.1);
    const divTwoAnimation = ScrollAnimation(start, end, 0.1);
    const divThreeAnimation = ScrollAnimation(start, end, 0.1);
    const divFourAnimation = ScrollAnimation(start, end, 0.1);
    const divFiveAnimation = ScrollAnimation(start, end, 0.1);

    return (
        <Layout>
            <Seo title="Features" description="Keeping things simple." />
            <div className="inttop"></div>
            <div className="app-title">
                <div className="container">
                    <div className="title">
                        <div className="title__txt">
                            <h1>
                                DigiClinic Features{' '}
                                <span>Keeping things simple.</span>
                            </h1>
                            <p>
                                By not overcomplicating your clinic management
                                software, you can concentrate on running your
                                clinic at its maximum potential.
                            </p>
                        </div>
                        <div className="title__img">
                            <Image src="feature-img.png" alt="Features" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-content">
                <div className="container">
                    <motion.div animate={divOneAnimation.animation}>
                        <div className="feature" ref={divOneAnimation.ref}>
                            <div className="feature__icon">
                                <span className="ficon">
                                    <Newspaper strokeWidth={0.75} size={64} />
                                </span>
                            </div>
                            <div className="feature__left">
                                <h1>Inventory Management</h1>
                                <p>
                                    Keep track of your inventory quickly and
                                    easily, never run out of critical stock.
                                </p>
                            </div>
                            <div className="feature__right">
                                <ul>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Real-time inventory information,
                                            linked to treatments.
                                        </span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Stock-level SMS and Email
                                            notifications.
                                        </span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Instantly request stock from your
                                            suppliers.
                                        </span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Weekly/Monthly inventory
                                            forecasting.
                                        </span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>Custom reporting.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div animate={divTwoAnimation.animation}>
                        <div className="feature" ref={divTwoAnimation.ref}>
                            <div className="feature__icon">
                                <span className="ficon">
                                    <PeopleGroup strokeWidth={0.75} size={64} />
                                </span>
                            </div>
                            <div className="feature__left">
                                <h1>Patient &amp; Treatment Management</h1>
                                <p>
                                    Manage Patient and Treatment data. Current
                                    and historical data at your finger tips.
                                </p>
                            </div>
                            <div className="feature__right">
                                <ul>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Treaments directly linked to
                                            invetory items.
                                        </span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>Custom patients notes.</span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Before and after comparisons.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div animate={divThreeAnimation.animation}>
                        <div className="feature" ref={divThreeAnimation.ref}>
                            <div className="feature__icon">
                                <span className="ficon">
                                    <Calendar strokeWidth={0.75} size={64} />
                                </span>
                            </div>
                            <div className="feature__left">
                                <h1>Treatment Bookings and Calendar</h1>
                                <p>
                                    Manage your week with integrated bookings,
                                    calendar and inventory forecasts.
                                </p>
                            </div>
                            <div className="feature__right">
                                <ul>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>Treatment calendar.</span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>Online booking portal.</span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Forecasts based on your treatment
                                            bookings.
                                        </span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Weekly/Monthly inventory
                                            forecasting.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div animate={divFourAnimation.animation}>
                        <div className="feature" ref={divFourAnimation.ref}>
                            <div className="feature__icon">
                                <span className="ficon">
                                    <Planet strokeWidth={0.75} size={64} />
                                </span>
                            </div>
                            <div className="feature__left">
                                <h1>Single or Multisite Management</h1>
                                <p>Manage one or multiple clinics easily.</p>
                            </div>
                            <div className="feature__right">
                                <ul>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Pricing options available for small
                                            or large scale clinics.
                                        </span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Multi-user permission management.
                                        </span>
                                    </li>
                                    <li>
                                        <CheckBoxFill
                                            strokeWidth={2}
                                            size={24}
                                        />{' '}
                                        <span>
                                            Custom user roles and views.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </motion.div>
                </div>
                <div
                    className="container internalint"
                    ref={divFiveAnimation.ref}
                >
                    <div className="intheader">
                        <h1>Integration Options</h1>
                    </div>
                    <motion.div animate={divFiveAnimation.animation}>
                        <div className="intitems">
                            <div>
                                <Image
                                    src="int-mailchimp.png"
                                    alt="Mailchimp"
                                    className="mailc"
                                />
                            </div>
                            <div>
                                <Image
                                    src="int-myob.png"
                                    alt="Myob"
                                    className="myob"
                                />
                            </div>
                            <div>
                                <Image
                                    src="int-xero.png"
                                    alt="Xero"
                                    className="xero"
                                />
                            </div>
                        </div>
                        <p>
                            If it has an API, we can intergrate it to
                            DigiClinic. Talk to us today about custom
                            integration.{' '}
                            <Link to="/" className="btn">
                                Book a Demo
                            </Link>
                        </p>
                    </motion.div>
                </div>
            </div>
        </Layout>
    );
}
